import "./App.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Layout from "./Pages/Layout/Layout";
import Home from "./Pages/Home/Home";
import Projects from "./Components/Projects/Projects";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="*" element={<p>Page not found!</p>}></Route>
        <Route path="/projects/*" element={<Projects />}></Route>
      </Route>
    )
  );
  return <RouterProvider router={router} />;
}

export default App;
