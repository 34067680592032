import styles from "./SkillsGrid.module.css";
import Skill from "./Skill";
import reactLogo from "./../../Images/react_logo.png";
import jsLogo from "./../../Images/js_logo.png";
import pythonLogo from "./../../Images/python_logo.png";
import gitLogo from "./../../Images/git_logo.png";
import cssLogo from "./../../Images/css_logo.png";
import nodejsLogo from "./../../Images/nodejs_logo.png";
import sqlLogo from "./../../Images/mysql_logo.png";
import flutterLogo from "./../../Images/flutter_logo.png";

function SkillsGrid() {
  return (
    <section className={styles["skills__grid"]}>
      <Skill heading="ReactJS" img={reactLogo} />
      <Skill heading="JavaScript" img={jsLogo} />
      <Skill heading="Python" img={pythonLogo} />
      <Skill heading="Git" img={gitLogo} />
      <Skill heading="CSS" img={cssLogo} />
      <Skill heading="NodeJS" img={nodejsLogo} />
      <Skill heading="SQL" img={sqlLogo} />
      <Skill heading="Flutter" img={flutterLogo} />
    </section>
  );
}

export default SkillsGrid;
