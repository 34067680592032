import { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./Project.module.css";

function Project({ icon, name, contents, color }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className={styles["project__container"]}>
      <div className={styles["project__header"]}>
        <small>Projects</small>
        <div className={styles["project__heading"]}>
          <img src={icon} alt="" />
          <h1 style={{ color }}>{name}</h1>
        </div>
      </div>
      <section className={styles["project__contents"]}>
        {contents.map((content, i) => {
          return (
            <section
              className={`${styles["project__content"]} ${
                i % 2 === 1 ? styles["project__content--reversed"] : ""
              }`}
            >
              <div className={styles["project__info"]}>
                <h3 style={{ color }}>{content.heading}</h3>
                <p>{content.desc}</p>
              </div>
              <img src={content.img} alt="" />
            </section>
          );
        })}
      </section>
      <div className={styles["project__actions"]}>
        <Link
          to="/"
          className={styles["back-to-home"]}
          style={{ backgroundColor: color }}
        >
          Back To Home
        </Link>
      </div>
    </section>
  );
}

export default Project;
