import styles from "./Skill.module.css";

function Skill({ heading, img }) {
  return (
    <article className={styles["skill__container"]}>
      <div className={styles["skill__content"]}>
        <img src={img} alt="" />
        <h3>{heading}</h3>
      </div>
    </article>
  );
}

export default Skill;
