import styles from "./ProjectPreview.module.css";
import { Link } from "react-router-dom";

function ProjectPreview({
  accentColor,
  buttonColor,
  boxShadowColor,
  heading,
  summary,
  demoTo,
  infoTo = "/404",
  img,
  icon,
  reversed = false,
  darkMode = false,
  showInfoBtn = true,
  showDemoBtn = true,
  demoBtnText = "Demo",
}) {
  return (
    <section
      className={`${styles["preview__container"]} ${
        reversed ? styles["preview__container--reversed"] : ""
      } ${darkMode ? styles["preview__container--dark"] : ""}`}
      style={{ backgroundColor: accentColor }}
    >
      <div className={styles["preview__title"]}>
        <img src={icon} alt="" className={styles["preview__icon"]} />
        <h3>{heading}</h3>
      </div>
      <p className={styles["preview__summary"]}>{summary}</p>
      <div className={styles["preview__actions"]}>
        {showDemoBtn && <Link to={demoTo} target="_blank">
          <button
            style={{
              backgroundColor: buttonColor,
              boxShadow: `-7px 7px ${boxShadowColor}`,
            }}
            className={styles["btn__primary"]}
          >
            {demoBtnText}
          </button>
        </Link>}
        {showInfoBtn && (
          <Link to={infoTo}>
            <button
              style={{ border: `2px solid ${buttonColor}`, color: buttonColor }}
              className={styles["btn__secondary"]}
            >
              Info
            </button>
          </Link>
        )}
      </div>
      <img
        src={img}
        alt=""
        className={`${styles["preview__img"]} ${
          reversed ? styles["preview__img--reversed"] : ""
        }`}
        draggable="false"
      />
    </section>
  );
}

export default ProjectPreview;
