import styles from "./AboutHero.module.css";
import heroImg from "./../../Images/hero-img.png";
import { Link } from "react-router-dom";
import resume from "./../../Resume/RoyChng_Resume.pdf";

function AboutHero() {
  return (
    <section className={styles["hero__container"]}>
      <div className={styles["hero__info"]}>
        <h1>Hey 👋, I'm Roy Chng</h1>
        <small>A Junior Software Developer</small>
        <div className={styles["hero__actions"]}>
          <a
            href="mailto:roychng21@gmail.com"
            target="_blank"
            className={`${styles["hero__btn"]} ${styles["hero__contact"]}`}
          >
            Contact
          </a>
          <a
            href={resume}
            target="_blank"
            className={`${styles["hero__btn"]} ${styles["hero__resume"]}`}
          >
            Resume
          </a>
        </div>
      </div>
      <div>
        <img
          src={heroImg}
          alt=""
          draggable="false"
          className={styles["hero__img"]}
          noindex
        />
      </div>
    </section>
  );
}

export default AboutHero;
