import { Route, Routes } from "react-router-dom";
import styles from "./Projects.module.css";
import Project from "./Project";
import pixifyIcon from "./../../Images/pixify_logo.png";
import pixifyImage1 from "./../../Images/projects/pixify-1.png";
import pixifyImage2 from "./../../Images/projects/pixify-2.png";
import pixifyImage3 from "./../../Images/projects/pixify-3.png";
import forkifyIcon from "./../../Images/forkify_logo.png";
import forkifyImage1 from "./../../Images/projects/forkify-1.png";
import forkifyImage2 from "./../../Images/projects/forkify-2.png";
import forkifyImage3 from "./../../Images/projects/forkify-3.png";
import metroIcon from "./../../Images/metro_logo.png";
import metroImage1 from "./../../Images/projects/metro-1.png";
import metroImage2 from "./../../Images/projects/metro-2.png";
import metroImage3 from "./../../Images/projects/metro-3.png";
import blogIcon from "./../../Images/blog_logo.png";
import blogImage1 from "./../../Images/projects/blog-1.png";
import blogImage2 from "./../../Images/projects/blog-2.png";
import blogImage3 from "./../../Images/projects/blog-3.png";

function Projects() {
  return (
    <Routes>
      <Route
        path="/pixify"
        element={
          <Project
            icon={pixifyIcon}
            name="Pixify"
            color="#EA5455"
            contents={[
              {
                heading: "Initial mockup",
                desc: "I drew up the initial mockup of Pixify in Adobe XD, including creating a style guide and outling the requirements of the application.",
                img: pixifyImage1,
              },
              {
                heading: "Using React-Router & Redux",
                desc: "To create the application, I used react-router to be able to perform client-side routing, as well as redux for app-wide state management",
                img: pixifyImage2,
              },
              {
                heading: "Final Application",
                desc: "The final application allows for the user to browse through images, search for specific ones, download the images, and save them to view them later",
                img: pixifyImage3,
              },
            ]}
          />
        }
      ></Route>
      <Route
        path="/forkify"
        element={
          <Project
            icon={forkifyIcon}
            name="Forkify"
            color="#F07B3F"
            contents={[
              {
                heading: "MVC Architecture",
                desc: "I implemented the model-view-controller architecture when building this project as part of a JavaScript course I enrolled in. This helps make the application scalable and maintainable.",
                img: forkifyImage1,
              },
              {
                heading: "Functionalities",
                desc: "The application allows the users to search, save and send the URL of recipes to others. It is a single-page-application the relies on vanilla JavaScript",
                img: forkifyImage2,
              },
              {
                heading: "Polyfilling",
                desc: "Polyfiling helps convert next-gen JavaScript to be compatible on older browsers, I did so by using Babel in this project",
                img: forkifyImage3,
              },
            ]}
          />
        }
      ></Route>
      <Route
        path="/metro-streetwear"
        element={
          <Project
            icon={metroIcon}
            name="Metro Streetwear"
            color="#0072BE"
            contents={[
              {
                heading: "Responsive Application",
                desc: "60% of internet traffic comes from mobile devices. In this application, I ensured such users will be able to easily view my application by using media queries to make the site responsive.",
                img: metroImage1,
              },
              {
                heading: "Functionalities",
                desc: "The application includes functionalities such as adding items to cart, checking out, as well as searching, sorting and filtering items.",
                img: metroImage2,
              },
              {
                heading: "Just JavaScript!",
                desc: "The application doesn't use any frameworks, just plain JavaScript, HTML and CSS! Bootstrap was also used to quickly create the mobile navigation menu and accordion component in the contact page.",
                img: metroImage3,
              },
            ]}
          />
        }
      ></Route>
      <Route
        path="/flask-blog"
        element={
          <Project
            icon={blogIcon}
            name="Blog"
            color="#009581"
            contents={[
              {
                heading: "User Authentication",
                desc: "Creating user accounts, and authenticating them is an essential part of any application. In this project, I implemented user authentication with the help of the Flask-Login extension.",
                img: blogImage1,
              },
              {
                heading: "Database Integration",
                desc: "Used SQL-Alchemy to store posts and comments data. Learnt about many-to-one and one-to-many relationships when integration the database to allow posts to have multiple comments and users to have multiple posts.",
                img: blogImage2,
              },
              {
                heading: "Preventing Common Vulnerabilities",
                desc: "Handling user data requires extreme caution. In this application, passwords are hashed before being stored, and any user input is escaped to prevent cross-site scripting (XSS).",
                img: blogImage3,
              },
            ]}
          />
        }
      ></Route>
    </Routes>
  );
}

export default Projects;
